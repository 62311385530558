/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { FieldSet, LAYOUT_OPTIONS } from '../shared/form/field-set/field-set.component';
import { FormCheckboxComponent } from '../shared/form/form-checkbox/form-checkbox.component';
import { FormPicklistComponent } from '../shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from '../shared/form/form-text/form-text.component';
import { FormConfig } from "../shared/form/FormConfig";
import { required } from '../shared/validators';
import { AbstractObject, uuid } from './abstract-object';
import { Frequency } from './frequency';
import { ScheduleCriteria } from './schedule-criteria';
import { FieldMaker } from '../shared/field/FieldMaker';

export type KnownChargeTypes = 1 | 2 | 3 | 4 | 5;

export class Schedule extends AbstractObject {
    static chargeId = {
        fixed: 1,
        bySize: 2,
        byType: 3,
        custom: 4,
        byCarSpace: 5,
        byBedrooms: 6,
    };

    static chargeTypes: AbstractObject[] = [
        { id: 1, name: 'Fixed Per Unit Charge' },
        { id: 2, name: 'Proportional By Unit Size' },
        { id: 3, name: 'Fixed By Type and/or Category' },
        { id: 4, name: 'Custom Proportion per unit' },
        { id: 5, name: 'Proportional By # Car spaces' },
        { id: 6, name: 'Proportional By # Bedrooms' },
    ];
    name: string;
    frequencyId: uuid;
    frequency: Frequency;
    chargeTypeId: KnownChargeTypes;
    chargeType?: AbstractObject;
    sinkingFund: boolean;
    criteria: ScheduleCriteria[] = [];
    portions: [] = [];
    portions_count?: number;

    incomeBCodeId: uuid;
    prepaidBCodeId: uuid;

    // Calculated Only Fields
    total?: number;
    prevTotal?: number;
    budget?: number;
    count?: number;


    constructor(o: Partial<Schedule> = {}) {
        super(o);
    }

    static pickDialogFormConfig(): FormConfig {
        return new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FieldMaker.id(),
                    FieldMaker.rev(),
                    FormTextComponent.make('Name', 'name', { validators: [required] }),
                    FormPicklistComponent.make('Charge Type', 'chargeTypeId', null,
                        { items: Schedule.chargeTypes }
                    ),
                    FormCheckboxComponent.make('Sinking Fund', 'sinkingFund', { formColumn: 2 }),
                    FieldMaker.notes(),
                ],
                formLayout: LAYOUT_OPTIONS.singleCol,
            }),
            title: 'Schedule',
            mode: 'list',
        });
    }
}
