/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpErrorResponse } from '@angular/common/http';
import { MsgDef } from 'src/app/shared/message.service';
import { KnownError } from './abstract-http.service';


export class HttpErrorHandler {

    protected knownErrors: KnownError[] = [
        {
            status: 401, code: 'rest_unauthorized', statusText: 'Unauthorized', msg: new MsgDef(
                $localize`You are not logged in or sessions has expired, please refresh page. (Windows press F5, Mac press Command + R) `, 'fail')
        },
        {
            status: 403, statusText: 'Forbidden', code: null, msg: new MsgDef($localize`You do not have required permissions`, 'fail')
        },
        {
            status: 404, code: null, statusText: 'Not Found', msg: new MsgDef(
                $localize`Item was not found - Are you sure you are in the correct organisation?`, 'fail')
        }
    ];

    recognise(err: KnownError) {
        this.knownErrors.unshift(err);
    }
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    getErrorMessage(response: HttpErrorResponse, operation: string): MsgDef {
        let returnMsg: MsgDef = new MsgDef($localize`An unknown error has just occured`, 'fail');

        if (response.error && response.error.code && response.error.message && response.error.ourOmcMessage) {
            console.log({response, operation});
            return new MsgDef(response.error.message, 'fail');
        }
        const knownOne = this.knownErrors.find(known => known.status === response.status
            && known.statusText === response.statusText
            && ((response.error && known.code && known.code === response.error.code) || known.code == null)
        );

        if (response.status === 422 && response.error?.errors) {
            console.warn('got a 422', response);
            let msg = "";
            Object.getOwnPropertyNames(response.error.errors).forEach(key => {
                msg += key + ':' + response.error.errors[key] + String.fromCharCode(10);
            })
            returnMsg = new MsgDef(msg, 'fail');
        }  else if (knownOne) {

            returnMsg = knownOne.msg;

        } else if (response.error && response.error.data && response.error.data.dbcode) {

            returnMsg = new MsgDef($localize`'${operation}:operation:' request failed to save.
                            '${response.error.data.dbcode}:dbErrorCode:':
                            '${response.error.data.dberror}:dbErrorMessage:' occured executing statement [
                            ${response.error.data.dbsql}:dbStatement:]`, 'fail');

        } else if (response.error && response.error.message) {

            returnMsg = new MsgDef($localize`'${operation}:operation:' request failed with error
          '${response.error.message}:errorMessage:'. (Response code : ${response.status}:statusCode:)`, 'fail');

        } else if (response.message) {

            returnMsg = new MsgDef($localize`'${operation}:operation:' request failed with error
          '${response.message}:errorMessage:'. (Response code : ${response.status}:statusCode:)`, 'fail');

        }

        return returnMsg;

    }

}
